<template>
  <div :key="asset._id">
    <PageHeaderDisplay class="mb-3" :title="asset.siteName" :sub-title="asset.address | address" :company="asset.company" :entity="asset.entity" />
    <div v-if="asset._id && asset.status && asset.status !== 'active'" class="content py-0">
      <div class="alert alert-warning mb-0">
        <strong><i class="fa fa-triangle-exclamation fa-fw"></i> INACTIVE</strong> This asset is currently inactive.
      </div>
    </div>
    <TabView route-name="asset-view" :tabs="tabs" :loading="loadingAction.get" :exact="false">
      <router-view v-if="asset._id" />
      <div v-else><h2>No asset has been selected, navigating back to list</h2></div>
    </TabView>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import PageHeaderDisplay from '@/components/PageHeaderDisplay';
import TabView from '@/components/TabView';

export default {
  name: 'AssetView',
  components: {
    PageHeaderDisplay,
    TabView
  },
  data() {
    return {
      searchTerm: '',
      ultimateParentEntity: null
    };
  },
  computed: {
    ...mapGetters({
      permissions: 'user/permissions',
      asset: 'asset/asset',
      loadingAction: 'asset/loadingAction'
    }),
    tabs() {
      const tabs = [
        {
          name: 'overview',
          route: 'asset-overview',
          icon: 'fa-building'
        },
        {
          name: 'accounts',
          route: 'asset-accounts',
          icon: 'fa-tachometer-alt'
        },
        {
          name: 'discussion',
          route: 'asset-discussion',
          icon: 'fa-comments-alt'
        },
        {
          name: 'reports',
          route: 'asset-reports',
          icon: 'fa-file-alt'
        },
        {
          name: 'projects',
          route: 'asset-projects-list',
          icon: 'fa-tasks'
        },
        {
          name: 'g&r',
          route: 'asset-recommendations',
          icon: 'fa-rectangle-list'
        },
        {
          name: 'documents',
          route: 'asset-documents-list',
          icon: 'fa-file'
        },
        {
          name: 'risks',
          route: 'asset-risks',
          icon: 'fa-exclamation-triangle'
        }
        // {
        //   name: 'tenants',
        //   route: 'asset-tenants',
        //   icon: 'fa-address-book'
        // },
      ];

      if (this.$permissions.write('asset', this.asset)) {
        tabs.push({
          name: 'manage',
          route: 'asset-manage',
          icon: 'fa-cog'
        });
      }

      return tabs.filter(tab => {
        if (tab.excludePermission) {
          return !tab.excludePermission.some(role => this.permissions.some(p => p.code === role));
        }

        return true;
      });
    }
  },
  async created() {
    await this.getAsset({ id: this.$route.params.id });

    if (!this.asset._id) {
      this.$router.push({ name: 'asset-list' });
    } else {
      this.getAssetGroups({ id: this.$route.params.id });
    }
  },
  beforeDestroy() {
    this.resetAsset();
  },
  methods: {
    ...mapActions({
      getAsset: 'asset/get',
      getAssetGroups: 'asset/getAssetGroups',
      getAssetDataSchemas: 'asset/getAssetDataSchemas',
      getEntity: 'entity/get'
    }),
    ...mapMutations({
      resetAsset: 'asset/RESET_STATE'
    })
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.params.id !== from.params.id) {
      this.resetAsset();
      await this.getAsset({ id: to.params.id });
    }
    next();
  }
};
</script>
